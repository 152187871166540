
import { defineComponent } from "vue";
import {loadPaginatedRebates}  from "@/api/myrebates";

export default defineComponent({
  name: "kt-widget-11", 
  methods: {
    async loadPaginatedMyRebates(){ 
      this.loading = true;
      var param = {page : 1 };
        const response = await loadPaginatedRebates(param);
        this.list = response.data.pageList;
        this.loading = false;
      }
  },
  mounted(){
      this.loadPaginatedMyRebates();
  },
  data(){
      return { 
        list : [],
        loading: true,
      }
  },
  components: {},
  props: {
    widgetClasses: String
  }
});
